<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">文章分类列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-button type="primary" @click="openDialog()" size="mini">添加</el-button>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists" style="width: 100%">
				<el-table-column align="center" width="120" prop="category_id" label="分类ID"></el-table-column>
				<el-table-column align="center" label="分类名称">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="分类排序">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.sort}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="openDialog(scope.row)" class="table-btn">修改
						</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" top="3vh" width="650px" height="60%">
				<!-- 添加/修改规则 -->
				<el-form :rules="rules" ref="form" :model="form" label-width="100px">
					<el-form-item label="分类名称" prop="name">
						<el-input v-model="form.name" placeholder="名称"></el-input>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
						<el-input-number :min="0" v-model="form.sort" placeholder="排序"></el-input-number>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submit('form')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				dialogVisible: false,
				dialogTitle: '添加分类',
				form: {
					name: '',
					sort: 100,
				},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					sort: [{
						required: true,
						message: '请输入排序',
						trigger: 'change'
					}],
				},
				category_id: 0,
				search: {
					s: 'store/content.article.category/index',
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 打开弹出层
			openDialog(item = false) {
				// 初始化表单
				if (!item || typeof item === 'number') {
					this.form = {
						name: '',
						sort: 50,
					}
					this.category_id = 0
				} else {
					this.axios({
						token: true,
						params: {
							category_id: item.category_id,
							s: 'store/content.article.category/edit'
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.dialogTitle = '编辑分类'
							this.form = {
								name: item.name,
								sort: item.sort,
							}
							this.category_id = item.category_id
						} else {
							return false;
						}
					}).catch(err => {})
				}
				// 显示弹出层
				this.dialogVisible = true
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						let params = {
							s: 'store/content.article.category/add'
						}
						if (this.category_id > 0) {
							params.s = 'store/content.article.category/edit';
							params.category_id = this.category_id;
						}
						this.axios({
							token: true,
							params: params,
							data: {
								category: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.dialogVisible = false;
								this.getList()
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该分类吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/content.article.category/delete',
							category_id: data.category_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						} else {
							this.$message({
								message: res.data.msg,
								type: 'error'
							});
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
